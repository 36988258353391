import { useNavigate, useParams } from 'react-router-dom';
import useHttp from '../severApi/UseHttp';
import { useContext, useState, useEffect, useCallback } from 'react';
import {UsersForm} from '../components/Users/UsersForm';
import { createUser } from '../severApi/ApiUsers';
import AuthContext from '../store/auth-context';
import getErrorMessage from '../components/Utils'
import { getUser, updateUser } from '../severApi/ApiUsers';
import { getAppPermissions } from '../severApi/ApiPermissions';
import UserData from '../components/Classes/UserData'
import AppPermission from '../components/Classes/AppPermission'


const EditUser = () => {
  const authCtx = useContext(AuthContext);
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<UserData>();
  const [permissions, setPermissions] = useState<{'availables':AppPermission[], 'assigned':AppPermission[]}>();

  let { user_id } = useParams();

  const editUserHandler = useCallback(async (userData:UserData) => {
    try {
      console.log("editUserHandler: " + JSON.stringify(userData))
      
      const userUpdated = await updateUser(authCtx.token, userData) ;
      console.log("editUserHandler updated: " + userUpdated);

      // TODO 
      // Ver como evitar pasar el token en cada funcion y que el token se use en api-users

    } catch (error) {
      setError(getErrorMessage(error));
    }
    // setIsLoading(false);
  }, []);
  
  
  const fetchUserHandler = useCallback(async () => {

    // setIsLoading(true);
    // setError(null);
    try {

      console.log("fetchUserHandler: " + user_id);
      if(user_id != undefined){
        const user_data = await getUser(authCtx.token, user_id) ;
        console.log("fetchUserHandler data: " + user_data);
        setUser(user_data);
      }

    } catch (error) {
      setError(getErrorMessage(error));
    }
    // setIsLoading(false);
  }, []);
  
  const fetchAppPermissionsHandler = useCallback(async () => {

    // setIsLoading(true);
    // setError(null);
    try {

      console.log("fetchAppPermissionsHandler");
      if(user_id != undefined){
        console.log("fetchAppPermissionsHandler data: ");
        const appPermissions = await getAppPermissions(authCtx.token) ;
        const permissions = {'availables':appPermissions, 'assigned':[]}
        setPermissions(permissions);
      }

    } catch (error) {
      setError(getErrorMessage(error));
    }
    // setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchUserHandler();
    fetchAppPermissionsHandler();
  }, [fetchUserHandler, fetchAppPermissionsHandler]);


  return <UsersForm saveUserHandler={editUserHandler} 
                    userData={user}
                    permissions={permissions}/>;
};

export default EditUser;
