import { useState } from "react";
import classes from "./DataTableHistory.module.css";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useHistoricalDataContext } from "../../HistoricalDataContext";
import UpIcon from '@mui/icons-material/ArrowUpward';
import DownIcon from '@mui/icons-material/ArrowDownward';
import React from "react";

const DataTableHistory = (props: any) => {

  const {setGeneralStartDate, setGeneralEndDate, setFilterGeneralDate, setSelectedDate} = useHistoricalDataContext();

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowSelected = (param: any) => {
    let selectedDate = new Date(param.row.recvTime);
    let offsetGeneralDate = parseInt(process.env.REACT_APP_OFFSET_GENERAL_DATE ?? "0", 10);
    let generalStartDate = new Date(selectedDate.getTime() - offsetGeneralDate);
    let generalEndDate = new Date(selectedDate.getTime() + offsetGeneralDate);
    setSelectedRow(param.id);
    setGeneralStartDate(generalStartDate);
    setGeneralEndDate(generalEndDate);
    setSelectedDate(selectedDate);

    let filter = `${generalStartDate && generalEndDate ? ` desde ${generalStartDate.toLocaleString()} hasta ${generalEndDate.toLocaleString()}` : ''}`;
    setFilterGeneralDate(filter);
  };

  function formatToLocalTime(date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const columns: GridColDef[] = [
    {
      field: "ruleTagName",
      headerName: "Regla",
      width: 300,
      editable: false,
    },
    {
      field: "temperatura",
      headerName: "Valor",
      type: "number",
      width: 110,
      editable: false,
    },
    {
      field: "flanco",
      headerName: "Flanco",
      width: 100,
      editable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            {params.value === "subida" ? <UpIcon style={{ color: 'red' }} /> : <DownIcon style={{ color: 'green' }} />}
          </div>
        );
      },
    },
    {
      field: 'recvTime',
      headerName: 'Fecha',
      flex: 0.2,
      editable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div>
              {formatToLocalTime(new Date (params.row.recvTime))}
            </div>
          </div>
        );
      }
    },
    
  ];

  const sortedRows = React.useMemo(() => {
    return [...props.rows].sort((a, b) => new Date(b.recvTime).getTime() - new Date(a.recvTime).getTime());
  }, [props.rows]);

  return (
      <div className={classes.card}>
        <div className={classes.card_content}>
          {props.loading ? (
            "Cargando datos..."
          ) : props.loaded ? (
            <>
              <div style={{width: "100%" }}>
                <DataGrid
                  rows={sortedRows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 20]}
                  getRowId={(row) => `${row.user}-${row.ruleTagName}-${row.recvTime}`}
                  className={classes.dataGridRoot}
                  autoHeight
                  onCellClick={(params) => handleRowSelected(params)}
                  getRowClassName={(params) =>
                    params.id === selectedRow ? classes.selectedRow : ''
                  }
                />
              </div>
            </>
          ) : (
            "No hay datos para la fecha seleccionada."
          )}
        </div>
      </div>
  );
};

export default DataTableHistory;
