import classes from "./Logo.module.css";
import image_lukat from './proteo-logo.png';

const Logo = (props: any) => {
  return (
    <div className={classes.container}>
        <img src={image_lukat}/>
    </div>
  );
};

export default Logo;
