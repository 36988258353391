import DevicesTable from "../components/Devices/DevicesTable";

import { TitleContext } from "../components/Context";
import React, { useContext, useState } from "react";
import RealTimeGraph from "../components/Devices/RealTimeGraph";
import Grid from "@mui/material/Grid";
import GeneralAnalysisGraph from "../components/Devices/GeneralAnalysisGraph";
import { FilterItem } from "../components/Devices/GeneralAnalysisGraph";
import NewReleasesCarrousell from "../components/Devices/NewReleasesCarrousell";
import { GridSelectionModel } from "@mui/x-data-grid";
import Speedometer from "../components/Devices/Speedometer";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import '@splidejs/react-splide/css';
import DashboardIcon from "../components/UI/DashboardIcon";

import classes from "../components/UI/Card.module.css";
import Card from "../components/UI/Card";


const DevicesPage = () => {
  const dateOffset = (24 * 60 * 60 * 1000) * 3; //5 days
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const [fromDate, setFromDate] = React.useState(new Date(new Date().getTime() - dateOffset));
  const [toDate, setToDate] = React.useState(new Date());

  const initialFilter: FilterItem = {
    column: "",
    operand: "",
    values: [],
    precio: "",
    marca: ""
  };

  const [filter, setFilter] = useState(initialFilter);

  const changeDevicesTable = (filterItem: FilterItem) => {
    setFilter(filterItem);
  };

  return (

    <div>

      <Grid container rowSpacing={1} spacing={0} columns={12} justifyContent="space-between" alignItems="center">

        <Grid item xs="auto" >
          <DashboardIcon title="MONITOREO" />
        </Grid>

        <Grid item xs="auto" container columns={12} justifyContent="space-between">

        </Grid>

      </Grid>

      <Card title={"ISLA DE FLORES"}>

        <Grid container rowSpacing={1} spacing={0} columns={12}>

          <Grid item xs={4} xl={4}>
            <Speedometer title="TEMPERATURA ACTUAL" height={290} segmentColors={["#a3be8c", "#ebcb8b", "#d08770", "#bf494f"]} segmentCount={4} />
          </Grid>
          <Grid item xs={4} xl={4}>
            <Speedometer title="TURBIDEZ ACTUAL" height={290} segmentColors={["#a3be8c", "#ebcb8b", "#d08770", "#bf616a", "#bf494f"]} segmentCount={5} />
          </Grid>
          <Grid item xs={4} xl={4}>
            <Speedometer title="SALINIDAD ACTUAL" height={290} segmentColors={["#a3be8c", "#ebcb8b", "#bf494f"]} segmentCount={3} />
          </Grid>

          <Grid item xs={12} >
            <RealTimeGraph title="EVOLUCIÓN DE TEMPERATURA" label="Temperatura" borderColor="#18265a" backgroundColor="#18265a" />
            <RealTimeGraph title="EVOLUCIÓN DE TURBIDEZ" label="Turbidez" borderColor="#C21292" backgroundColor="#C21292" />
            <RealTimeGraph title="EVOLUCIÓN DE SALINIDAD" label="Salinidad" borderColor="#FFA732" backgroundColor="#FFA732" />
          </Grid>
        </Grid >
      </Card>
    </div>

  );
};

export default DevicesPage;
