import {Users} from '../components/Users/Users';
import { useContext } from 'react';
import { useState, useEffect, useCallback } from 'react';
import getErrorMessage from '../components/Utils'
import AuthContext from '../store/auth-context';
import useHttp from '../severApi/UseHttp';
import { getUsers } from '../severApi/ApiUsers';
import UserData from '../components/Classes/UserData'

const UsersPage = () => {

  const { sendRequest, status, data: loadedQuotes, errorServer } = useHttp(getUsers, true);

  // interface User{
  //   id:string,
  //   email: string,
  //   first_name: string
  // }
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [users, setUsers] = useState<UserData[] | undefined>(undefined);

  const fetchUsersHandler = useCallback(async () => {
    // setIsLoading(true);
    // setError(null);
    try {
      const loadedUsers:UserData[] = [];

      // TODO
      // Ver como evitar pasar el token en cada funcion y que el token se use en api-users
      const data = await getUsers() ;
      for (const key in data) {
        let user:UserData = {
          id: data[key].id,
          email: data[key].email,
          firstName: data[key].first_name,
          lastName: data[key].last_name
        }
        loadedUsers.push(user);
      }

      setUsers(loadedUsers);

    } catch (error) {
      setError(getErrorMessage(error));
    }
    // setIsLoading(false);
  }, []);


  useEffect(() => {
    fetchUsersHandler();
  }, [fetchUsersHandler]);

  return <Users users={users}/>;
};

export default UsersPage;
