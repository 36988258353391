import Avatar from '@mui/material/Avatar';
import SpeedOutlined from '@mui/icons-material/SpeedOutlined';
import HistoryToggleOffOutlined from '@mui/icons-material/HistoryToggleOffOutlined';
import { makeStyles } from "@material-ui/core/styles";
import { CardHeader } from '@mui/material';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: '#455072',
        color: '#99ffff',
    }
}));
function renderSwitch(title: string, classes: ClassNameMap<"avatar">) {
    switch (title) {
        case "TERMINALES":
            return (
                <CardHeader
                    avatar={
                        < Avatar sx={{ bgcolor: '#455072', color: '#99ffff', width: 56, height: 56 }} className={classes.avatar} >
                            <SpeedOutlined fontSize="large" />
                        </Avatar >
                    }
                    title={title}
                    titleTypographyProps={{fontFamily:"Montserrat_Bold"}}
                />
            );

        case 'ANÁLISIS HISTÓRICO':
            return (
                <CardHeader
                    avatar={
                        < Avatar sx={{ bgcolor: '#455072', color: '#99ffff', width: 56, height: 56 }} className={classes.avatar} >
                            <HistoryToggleOffOutlined fontSize="large" />
                        </Avatar >
                    }
                    title={title}
                    titleTypographyProps={{fontFamily:"Montserrat_Bold"}}
                />
            );

        default:
            return (
                <CardHeader
                    avatar={
                        < Avatar sx={{ bgcolor: '#455072', color: '#99ffff', width: 56, height: 56 }} className={classes.avatar} >
                            <SpeedOutlined fontSize="large" />
                        </Avatar >
                    }
                    title={title}
                    titleTypographyProps={{fontFamily:"Montserrat_Bold"}}
                />
            );
    };
};

const DashboardIcon = (props: any) => {
    const classes = useStyles();

    return (
        <div>{ renderSwitch(props.title, classes) }</div>
    );
};

export default DashboardIcon;

