import { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import SignInSide from "./components/Auth/SignInSide";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import Layout from "./components/Layout/Layout";
import UsersPage from "./pages/UsersPage";
import AuthContext from "./store/auth-context";
import UsersCreatePage from "./pages/UsersCreatePage";
import UsersEditPage from "./pages/UsersEditPage";
import { routes } from "./config/routing/Routes";
import AppRoute from "./config/routing/AppRouting";
import { PageDoesNotExist } from "./pages/errors/PageDoesNotExist";
import { TitleContext, ValueContextType } from "./components/Context";
import DevicesPage from "./pages/DevicesPage";

import { ProtectedRoute } from "./ProtectedRoute";

// on top of the file:
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from 'axios'

import { NotificationProvider } from "./NotificationContext";
import { HistoricalDataProvider } from "./HistoricalDataContext";
import HistoricalDataPage from "./pages/HistoricalDataPage";



function App() {

  const authCtx = useContext(AuthContext);

  const [title, setTitle] = useState<ValueContextType | any>("");
  // const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState<string | null>(null);

  // on your component:
  const navigate = useNavigate();

  // Add a request interceptor
  // https://lightrains.com/blogs/axios-intercepetors-react/
  axios.interceptors.request.use(
    config => {
      const token = authCtx.token
      if (token && config.headers != undefined) {
        config.headers['Authorization'] = 'Bearer ' + token
        config.headers['Content-Type'] = 'application/json';
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

  let element;
  const dev:Boolean = process.env.NODE_ENV.trim() === "development"
  if (!dev) {
    element = (
      <ProtectedRoute>
        <NotificationProvider>
          <Layout />
        </NotificationProvider>
      </ProtectedRoute>
    );
  } else {
    element = (
      <NotificationProvider>
        <Layout />
      </NotificationProvider>
    );
  }

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      <NotificationProvider>
        <HistoricalDataProvider>
          <Routes>
            <Route element={element}>
              <Route path="/users" element={<UsersPage />} />
              <Route path="/users-create" element={<UsersCreatePage />} />
              <Route path="/users-edit/:user_id" element={<UsersEditPage />} />
              <Route path="/devices" element={<DevicesPage />} />
              <Route path="/history" element={<HistoricalDataPage/>} />
            </Route>
            <Route path="*" element={<LoginPage />} />
          </Routes>
        </HistoricalDataProvider>
      </NotificationProvider>
    </TitleContext.Provider>
  );
}

export default App;
