import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classes from "../components/UI/Card.module.css";
import { useHistoricalDataContext } from "../HistoricalDataContext";

const DateRangePicker: React.FC = () => {

  const { alertStartDate, alertEndDate, setAlertStartDate, setAlertEndDate, setGeneralStartDate, setGeneralEndDate } = useHistoricalDataContext();

  const handleStartDateChange = (date: Date) => {
    setAlertStartDate(date);
    setGeneralStartDate(date);
  };

  const handleEndDateChange = (date: Date) => {
    setAlertEndDate(date);
    setGeneralEndDate(date);
  };

  return (
    <div
      className={classes.dateRangePicker_input}
      style={{ display: "flex", alignItems: "center" }}
    >
      <div style={{ marginRight: "10px" }}>
        <DatePicker
          selected={alertStartDate}
          onChange={handleStartDateChange}
          showTimeSelect
          timeFormat="HH:mm:ss"
          timeIntervals={15}
          dateFormat="yyyy-MM-dd HH:mm:ss"
        />
      </div>
      <div>
        <span>-</span>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <DatePicker
          selected={alertEndDate}
          onChange={handleEndDateChange}
          showTimeSelect
          timeFormat="HH:mm:ss"
          timeIntervals={15}
          dateFormat="yyyy-MM-dd HH:mm:ss"
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
