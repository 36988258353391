import classes from "./Card.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Tooltip from "@mui/material/Tooltip";
import { Fragment } from "react";
import IconButton from '@mui/material/IconButton';

const Card = (props: any) => {
  return (
    <div className={classes.card}>
      <div className={classes.header}>
        {props.title}
        {props.filters?
          <div className={classes.filterContainer}>
            <FontAwesomeIcon icon={faFilter} />
            <div className={classes.filterDialog}>
              {props.filters}
              {props.filtersClear?
                <IconButton onClick={props.filtersClear}>
                  <FontAwesomeIcon icon={faFilterCircleXmark} />
                </IconButton>
                : ""
              }
            </div>
          </div>
          : ""
        }
      </div>
      <div className={classes.card_content}>{props.children}</div>
    </div>
  );
};

export default Card;
