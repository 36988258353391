import React, { useState, useEffect } from 'react';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  userEmail: '',
  isLoggedIn: false,
  login: (token, expirationTime, userEmail) => {},
  logout: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');
  const storedExpirationDate = localStorage.getItem('expirationTime');
  const storedEmail = localStorage.getItem('userEmail');

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('userEmail');
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
    email: storedEmail
  };
};

export const AuthContextProvider = (props) => {

  const tokenData = retrieveStoredToken();
  
  let initialToken;
  let initialEmail;
  if (tokenData) {
    initialToken = tokenData.token;
    initialEmail = tokenData.email;
  }

  const [token, setToken] = useState(initialToken);

  const userIsLoggedIn = !!token;

  const [email, setEmail] = useState(initialEmail);

  const loginJWTHandler = (token, expirationTime, userEmail) => {
    setToken(token);
    setEmail(userEmail);
    
    localStorage.setItem('token', token);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('userEmail', userEmail);

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutJWTHandler, remainingTime);
  };

  const logoutJWTHandler = () => {
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('userEmail');

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  };

  const loginCookieHandler = (token=undefined,expirationTime) => {
    localStorage.setItem('expirationTime', expirationTime);
    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(logoutCookieHandler, remainingTime);
  };

  const logoutCookieHandler = () => {
    localStorage.removeItem('expirationTime');
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutJWTHandler, tokenData.duration);
    }
  }, [tokenData, logoutJWTHandler]);
  
  const contextValue = {
    token: token,
    userEmail: email,
    isLoggedIn: userIsLoggedIn,
    isDev: true,
    login: loginJWTHandler,
    logout: logoutJWTHandler,
  };
  
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;