import axios from "axios";
import { useEffect, useState } from "react";

interface backResponseEl {
  user: string;
  ruleTagName: string;
  temperatura: number;
  flanco: string;
  recvTime: Date;
}

export interface AlertsResponse {
  user: string;
  ruleTagName: string;
  temperatura: number;
  flanco: string;
  recvTime: Date;
}

export type ServiceResponse = AlertsResponse[];

export function useGetAlerts(
  context: string,
  fecha_desde: string,
  fecha_hasta: string,
  startLoading: Function,
  stopLoading: Function,
  hasLoadedData: Function,
  noLoadedData: Function
): ServiceResponse {
  const [alerts, setAlerts] = useState<ServiceResponse>([]);

  useEffect(() => {
    if (fecha_desde && fecha_hasta) {
      let didCancel = false;
      startLoading();
      axios
        .get(
          `${
            process.env.REACT_APP_API_HOST || "http://localhost:8080"
          }/api/v1/contexts/${context}/alerts`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            params: {
              fecha_desde: fecha_desde,
              fecha_hasta: fecha_hasta,
            },
          }
        )
        .then(async (response) => {
          let alertsResponse: ServiceResponse = [];
          response.data.forEach((alertBack: backResponseEl) => {
            const newAlertEl: AlertsResponse = {
              user: alertBack.user,
              ruleTagName: alertBack.ruleTagName,
              temperatura: alertBack.temperatura,
              flanco: alertBack.flanco,
              recvTime: alertBack.recvTime,
            };
            alertsResponse.push(newAlertEl);
          });
          
          if (alertsResponse.length > 0) {
            setAlerts(alertsResponse);
            hasLoadedData();
          } else {
            noLoadedData();
          }
        })
        .catch(function (error) {
          console.log("error", error);
          noLoadedData();
          // throw new Error(error || "Could not fetch promotions.");
        })
        .finally(() => {
          stopLoading();
        });
      return () => {
        didCancel = true;
      };
    }
  }, [fecha_desde, fecha_hasta]);
  return alerts;
}
