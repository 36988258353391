import { Divider, Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import { FormControl, InputLabel, FormHelperText, Input, Grid, Button} from '@mui/material';
import TransferList from '../UI/TransferList'
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import UserData from '../Classes/UserData'
import { v4 as uuidv4 } from 'uuid';


// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   }));

const UserInputs = (props:any) => {

    const [id, setId] = useState<typeof uuidv4>();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [assignedPermissions, setAssignedPermissions] = useState('');
    const [availablesPermissions, setAvailablesPermissions] = useState('');
        
    const saveUser = (event: { preventDefault: () => void; }) => {
        console.log('saveUser test')
        event.preventDefault();
        let user_data = new UserData();

        user_data.id = id;
        user_data.firstName = firstName;
        user_data.lastName = lastName;
        user_data.email = email;

        props.saveUserHandler(user_data);
    };

    useEffect(()=>{
        if( props.userData != undefined){
            let userData = props.userData;
            setId(userData.id);
            setFirstName(userData.firstName);
            setLastName(userData.lastName);
            setEmail(userData.email);
        }
        if( props.permissions != undefined){
            let availables = props.permissions.availables;
            let assigned = props.permissions.assigned;
            setAvailablesPermissions(availables);
            setAssignedPermissions(assigned);
        }
    }, [props]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={3} md={4}>
                    <FormControl fullWidth={true}>
                        <InputLabel htmlFor="my-input">Nombre</InputLabel>
                        <Input id="my-input" aria-describedby="my-helper-text" 
                            value={firstName}
                            onChange={event => {
                                setFirstName(event.target.value);
                            }}
                        />
                        {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                    <FormControl fullWidth={true}>
                        <InputLabel htmlFor="last_name">Apellido</InputLabel>
                        <Input id="last_name" aria-describedby="last_name-helper-text" 
                            value={lastName}
                            onChange={event => {
                                setLastName(event.target.value);
                            }}
                        />
                        {/* <FormHelperText id="last_name-helper-text"></FormHelperText> */}
                    </FormControl>
                </Grid>
                <Grid item xs={4} md={4}>
                    <FormControl fullWidth={true}>
                        <InputLabel htmlFor="mail">Mail</InputLabel>
                        <Input id="mail" aria-describedby="mail-helper-text" 
                            value={email}
                            onChange={event => {
                                setEmail(event.target.value);
                            }}/>
                        {/* <FormHelperText id="last_name-helper-text"></FormHelperText> */}
                    </FormControl>
                </Grid>
                <Grid item>
                    <TransferList left={assignedPermissions} right={availablesPermissions}></TransferList>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={saveUser}> Guardar</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" href="/users" color="secondary"> Cancelar</Button>
                </Grid>
            </Grid>
        </Box>

    );
};

export default UserInputs;

        // <Box flex="1" mt={-1}>
        //     <Box display="flex" width={430}>
        //         <TextField label="first_name" fullWidth />
        //         <Spacer />
        //         <TextField label="last_name" fullWidth />
        //     </Box>
        //     <Box display="flex" width={430}>
        //         <TextField label="title" fullWidth />
        //         <Spacer />
        //         {/* <ReferenceInput label="company_id" reference="companies">
        //             <AutocompleteInput optionText="name" fullWidth />
        //         </ReferenceInput> */}
        //     </Box>
        //     <Divider />
        //     <Box mt={2} width={430}>
        //         <TextField label="email" fullWidth />
        //     </Box>
        //     <Box display="flex" width={430}>
        //         <TextField label="phone_number1" fullWidth />
        //         <Spacer />
        //         <TextField label="phone_number2" fullWidth />
        //     </Box>
        //     <Divider />
        //     <Box mt={2} width={430}>
        //         <TextField label="background" multiline fullWidth />
        //         <TextField label="avatar" fullWidth />
        //         {/* <BooleanInput label="has_newsletter" /> */}
        //     </Box>
        // </Box>

// const Spacer = () => <Box width={20} component="span" />;