import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
} from "chart.js";
import "chartjs-adapter-moment";
import classes from "./HistoricalEvolutionGraph.module.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: "",
    },
  },
  scales: {
    x: {
      type: "time" as const,
      gridLines: {
        lineWidth: 2,
      },
      time: {
        unit: "day" as const,
        unitStepSize: 1000,
        displayFormats: {
          millisecond: "MMM DD",
          second: "MMM DD",
          minute: "MMM DD",
          hour: "MMM DD",
          day: "MMM DD",
          week: "MMM DD",
          month: "MMM DD",
          quarter: "MMM DD",
          year: "MMM DD",
        },
      },
    },
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
  },
};

interface HistoricalEvolutionGraphProps {
  title: string;
  dataSet: DataSet[];
  borderColor: string;
  backgroundColor: string;
  specificDate: Date | undefined;
}

interface DataSet {
  value: number;
  recvTime: Date;
}

const HistoricalEvolutionGraph = ({
  title,
  dataSet,
  specificDate,
  borderColor,
  backgroundColor,
}: HistoricalEvolutionGraphProps) => {
  let specificIndex: number;
  if (specificDate !== undefined) {
    const specificDateObj = new Date(specificDate);
    specificIndex = dataSet.findIndex((data) => {
      const dataDate = new Date(data.recvTime);
      return dataDate.getTime() === specificDateObj.getTime();
    });
  }

  const transformData = (
    dataSet: DataSet[],
    label: string,
    borderColor: string,
    backgroundColor: string
  ) => {
    return {
      label,
      data: dataSet.map((data) => ({
        x: new Date(data.recvTime),
        y: data.value,
      })),
      borderColor: borderColor,
      backgroundColor: backgroundColor,
      yAxisID: "y",
      pointRadius: dataSet.map((_, index) =>
        typeof specificIndex === "number" && index === specificIndex ? 5 : 0
      ),
      pointBackgroundColor: dataSet.map(
        (_, index) => (index === specificIndex ? "#007FFF" : backgroundColor)
      ),
    };
  };

  const chartData = {
    label: dataSet.map((data) => new Date(data.recvTime)),
    datasets: [transformData(dataSet, title, borderColor, backgroundColor)],
  };

  return (
    <section>
      <div className={classes.card}>
        <div className={classes.card_content}>
          <div className={classes.graphContainer}>
            <Line options={options} data={chartData} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HistoricalEvolutionGraph;
