import React, { useContext, useEffect, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import Card from "../UI/Card";
import classes from './Speedometer.module.css';
import { useNotificationContext } from "../../NotificationContext";

const Speedometer = (props: { title: string; height: number; segmentColors: string[]; segmentCount: number }) => {
    const { notifications } = useNotificationContext();
    const [temperature, setTemperature] = useState(0);

    useEffect(() => {
        if (notifications.length > 0) {
            const latestNotification = notifications[0];

            if (latestNotification && latestNotification.action) {
                const matchResult = latestNotification.action.match(/temperatura:\s*([+-]?\d*\.?\d+)/i);
                const numericValue = matchResult ? parseFloat(matchResult[1]) : 0;

                // Verificar si el título contiene "TEMPERATURA ACTUAL"
                if (props.title.includes("TEMPERATURA ACTUAL")) {
                    // Si es true, usar el valor original sin agregar aleatorio
                    setTemperature(numericValue);
                } else {
                    // Si es false, agregar un valor aleatorio entre 0 y 15
                    const randomValue = Math.floor(Math.random() * 16);
                    const newValue = numericValue + randomValue;
                    setTemperature(newValue);
                }
            }
        }
    }, [notifications, props.title]);

    return (
        <section className={classes.devices}>
            <Card title={props.title}>
                <div className={classes.speedometerContainer}>
                    <ReactSpeedometer
                        maxValue={100}
                        minValue={0}
                        height={props.height}
                        width={490}
                        value={temperature}
                        needleTransitionDuration={1000}
                        needleColor="orange"
                        segments={props.segmentCount}
                        segmentColors={props.segmentColors}
                    />
                </div>
            </Card>
        </section>
    );
};

export default Speedometer;
