import Card from "../UI/Card";
import { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, TimeSeriesScale } from 'chart.js';
import 'chartjs-adapter-moment';
import { getTelcoColor } from "../../utils/helpers/telcoColor";
import AuthContext from "../../store/auth-context";
import { useContext } from "react";
import { useNotificationContext } from "../../NotificationContext"; // ajusta la ruta según tu estructura de archivos

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: '',
    },
  },
  scales: {
    x: {
      type: 'time' as const,
      gridLines: {
        lineWidth: 2
      },
      time: {
        unit: 'day' as const,
        unitStepSize: 1000,
        displayFormats: {
          millisecond: 'MMM DD',
          second: 'MMM DD',
          minute: 'MMM DD',
          hour: 'MMM DD',
          day: 'MMM DD',
          week: 'MMM DD',
          month: 'MMM DD',
          quarter: 'MMM DD',
          year: 'MMM DD',
        },
      }
    },
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    }
  },
};

const RealTimeGraph = (props: { title: string; label: string; borderColor: string; backgroundColor: string }) => {
  const [data, setData] = useState({
    labels: [] as Date[],
    datasets: [] as any[],
  });

  const { notifications } = useNotificationContext();

  const formatDateString = (dateString?: string) => {
    if (!dateString) {
      console.error('Fecha indefinida o nula.');
      return '';
    }

  // Verificar si el valor es una fecha válida
  const parsedDate = new Date(dateString);
  if (isNaN(parsedDate.getTime())) {
    console.error('Formato de fecha no válido:', dateString);
    return '';
  }

  console.log('Iniciando transformación de fecha para:', dateString);
  return parsedDate.toISOString(); 
  };

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    const now: Date = new Date();
    if (notifications.length > 0) {
      console.log('Notificaciones recibidas:', notifications);
      const latestNotification = notifications[0];
      //TODO generalizarlo al resto de los attrs
      if (latestNotification && latestNotification.action) {
        //const timeTransformed = formatDateString(latestNotification.recvTime);
        //const timeTransformed = formatDateString(now.toISOString());
        console.log('action: ', latestNotification.action);
        console.log('recvTimePE: ', now);
        const matchResult = latestNotification.action.match(/temperatura:\s*([+-]?\d*\.?\d+)/i);
        const numericValue = matchResult ? matchResult[1] : null;

        setData((prevData) => {
          //const newDate = new Date(timeTransformed);
          const newDate = new Date();
          if (
            prevData.labels.length === 0 ||
            newDate.getTime() > prevData.labels[prevData.labels.length - 1].getTime()
          ) {
            const newLabels = [...prevData.labels, newDate];
            const newDatasets = [
              {
                label: props.label,
                data: [
                  ...prevData.datasets[0]?.data || [],
                  { x: newDate, y: numericValue },
                ],
                borderColor: props.borderColor,
                backgroundColor: props.backgroundColor,
                yAxisID: 'y',
                pointRadius: 0,
              },
            ];

            return {
              labels: newLabels,
              datasets: newDatasets,
            };
          } else {
            return prevData;
          }
        });

        console.log('actualizando datos: ',  numericValue);
      }
    }
  }, [notifications]);
  
  return (
    <section>
      <Card title={props.title} >
        <div>
          {data.labels.length === 0 && 'No hay datos para mostrar.'}
          {data.labels.length > 0 && <Line options={options} data={data} />}
        </div>
      </Card>
    </section>
  );
};

export default RealTimeGraph;
