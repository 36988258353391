import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { DataGrid, GridColDef, GridApi, GridCellValue, GridValueGetterParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Toolbar, IconButton  } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link, NavLink } from 'react-router-dom';
import classes from './Users.module.css';

const mdTheme = createTheme();
function UsersContent(props:any) {

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90, hide:true },
    {
      field: 'firstName',
      headerName: 'Nombre',
      width: 150,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: 'Apellido',
      width: 150,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'number',
      width: 310,
      editable: true,
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.first_name || ''} ${params.row.last_name || ''}`,
    }, 
    {
      field: "action",
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        const onClickEdit = (e:any) => {
          e.stopPropagation(); // don't select this row after clicking
  
          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};
  
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
  
          return alert(JSON.stringify(thisRow, null, 4));
        };
        const onClickDelete = (e:any) => {
          e.stopPropagation(); // don't select this row after clicking
  
          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};
  
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
  
          // return alert(JSON.stringify(thisRow, null, 4));
        };
  
        return <>
        {/* <Link to={`/users-edit/${params.id}`}>Edit</Link> */}
        {/* <IconButton  onClick={onClickEdit}><EditIcon /></IconButton> */}
        <IconButton  href={`/users-edit/${params.id}`}><EditIcon /></IconButton>
        <IconButton  onClick={onClickDelete}><DeleteIcon /></IconButton>
        </>
      }
    },
  ];
  let rows = [];
  
  if (props != undefined && props.users != undefined){
    rows = props.users;
  }
  let activeClassName = classes.active;

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Toolbar sx={{ justifyContent: "space-between" }}> 
        <NavLink to='/users-create' className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
          >
          Crear
        </NavLink>
      </Toolbar>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 500,
          }}
          >
            <Box sx={{ height: 800, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // checkboxSelection
                // disableSelectionOnClick
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>

  );
}

export function Users(props:any) {
  return <UsersContent users={props.users}/>;
}
