import { Fragment, useRef, useState } from 'react';
import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import UserInputs from './UserInputs'
import {CreateBase} from '../UI/CreateBase'

// import Card from '../UI/Card';
// import LoadingSpinner from '../UI/LoadingSpinner';
import classes from './UsersForm.module.css';

function UsersFormContent(props:any) {
  
  return (
    <Fragment>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
              <CreateBase>
                <UserInputs saveUserHandler={props.saveUserHandler} 
                            userData={props.userData}
                            permissions={props.permissions}/>
              </CreateBase>
            </Paper>
      </Container>
    </Fragment>
  )
}

export function UsersForm(props:any) {
  return <UsersFormContent saveUserHandler={props.saveUserHandler} 
                           userData={props.userData}
                           permissions={props.permissions}/>;
}

