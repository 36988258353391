
import { v4 as uuidv4 } from 'uuid';

class UserData {
    id: typeof uuidv4 | undefined;
    firstName: string| undefined;
    lastName: string| undefined;
    email: string| undefined;
  }

  export default UserData;