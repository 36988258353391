import React, { createContext, useContext, useState, ReactNode, useMemo } from "react";

interface HistoricalDataContextProps {
  // Fechas para alertas
  alertStartDate: Date;
  alertEndDate: Date;
  setAlertStartDate: (date: Date) => void;
  setAlertEndDate: (date: Date) => void;

  // Fechas para datos generales
  generalStartDate: Date;
  generalEndDate: Date;
  setGeneralStartDate: (date: Date) => void;
  setGeneralEndDate: (date: Date) => void;

  // Filtro aplicado a las fechas generales
  filterGeneralDate: string | null;
  setFilterGeneralDate: (filterGeneralDate: string | null) => void;
  clearFilterGeneralDate: () => void; 

  //Fecha seleccionada
  selectedDate: Date | undefined;
  setSelectedDate: (date: Date | undefined) => void;
}

const HistoricalDataContext = createContext<HistoricalDataContextProps | undefined>(undefined);

export const useHistoricalDataContext = () => {
  const context = useContext(HistoricalDataContext);
  if (!context) {
    throw new Error("useHistoricalDataContext debe ser utilizado dentro de HistoricalDataProvider");
  }
  return context;
};

interface HistoricalDataProviderProps {
  children: ReactNode;
}

export const HistoricalDataProvider: React.FC<HistoricalDataProviderProps> = ({ children }) => {

  const offsetNow = parseInt(process.env.REACT_APP_OFFSET_NOW ?? "0", 10);
  const offsetStartDate = parseInt(process.env.REACT_APP_OFFSET_START_DATE ?? "0", 10);


  // Valores iniciales guardados para poder restablecerlos
  const now = new Date();
  now.setMinutes(now.getMinutes() - offsetNow/60000);
  const initialStartDate = useMemo(() => new Date(now.getTime() - offsetStartDate), []);
  const initialEndDate = useMemo(() => new Date(now), []);
  

  const [alertStartDate, setAlertStartDate] = useState<Date>(initialStartDate);
  const [alertEndDate, setAlertEndDate] = useState<Date>(initialEndDate);
  const [generalStartDate, setGeneralStartDate] = useState<Date>(initialStartDate);
  const [generalEndDate, setGeneralEndDate] = useState<Date>(initialEndDate);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const updateAlertStartDate = (date: Date) => {
    setAlertStartDate(date);
  };

  const updateAlertEndDate = (date: Date) => {
    const now = new Date();
    if (date > now) {
      const adjustedDate = new Date(now.getTime() - 0.1 * 60000);
      setAlertEndDate(adjustedDate);
    } else {
      setAlertEndDate(date);
    }
  };

  const updateGeneralStartDate = (date: Date) => {
    setGeneralStartDate(date);
  };

  const updateGeneralEndDate = (date: Date) => {
    const now = new Date();
    if (date > now) {
      const adjustedDate = new Date(now.getTime() - 0.1 * 60000);
      setGeneralEndDate(adjustedDate);
    } else {
      setGeneralEndDate(date);
    }
  };

  const updateSelectedDate = (date: Date | undefined) => {
    setSelectedDate(date);
  };

  // Estado para el filtro
  const [filterGeneralDate, setFilterGeneralDate] = useState<string | null>(null);

  const updateGFilterGeneralDate = (filter : string | null) => {
    setFilterGeneralDate(filter);
  };

  // Función para limpiar el filtro y restablecer las fechas generales
  const clearFilterGeneralDate = () => {
    setGeneralStartDate(initialStartDate);
    setGeneralEndDate(initialEndDate);
    setFilterGeneralDate(null);
    setSelectedDate(undefined);
  };

  const contextValue: HistoricalDataContextProps = {
    alertStartDate,
    alertEndDate,
    setAlertStartDate: updateAlertStartDate,
    setAlertEndDate: updateAlertEndDate,
    generalStartDate,
    generalEndDate,
    setGeneralStartDate: updateGeneralStartDate,
    setGeneralEndDate: updateGeneralEndDate,
    filterGeneralDate,
    setFilterGeneralDate: updateGFilterGeneralDate,
    clearFilterGeneralDate: clearFilterGeneralDate,
    selectedDate,
    setSelectedDate: updateSelectedDate
  };

  return (
    <HistoricalDataContext.Provider value={contextValue}>
      {children}
    </HistoricalDataContext.Provider>
  );
};
